.layout-contact{
  .module-content{
    &-wrapper{
      padding-top: rem-calc(100);
      padding-bottom: rem-calc(48);
      @include media-breakpoint-up(sm) {
        border-bottom: rem-calc(8) solid $primary;
        padding-bottom: rem-calc(100);
      }
      .column-left{
        text-align: -webkit-center;
        max-width: rem-calc(482);
        .title-contact{
          max-width: rem-calc(300);
          font-size: rem-calc(40);
          font-weight: 700;
          text-transform: uppercase;
          text-align: -webkit-center;
          color: $primary;
          @include media-breakpoint-up(sm) {
            max-width: rem-calc(482);
            font-size: rem-calc(60);
          }
          @include media-breakpoint-up(lg) {
            font-size: rem-calc(75);
          }
        }
        .item-figure-contacto{
          max-width: rem-calc(300);
          border: rem-calc(16) solid $primary;
          border-radius: 50%;
          @include media-breakpoint-up(sm) {
            max-width: rem-calc(482);
          }
          img{
            border-radius: 50%;
          }
        }
      }
      .column-right{
        @include media-breakpoint-up(sm) {
          max-width: rem-calc(482);
        }
        .description-form{
          color: $primary;
          text-align: center;
          @include media-breakpoint-up(md) {
            text-align: start;
          }
        }
        .response{
          color: $primary;
          border: rem-calc(1) solid $primary;
          text-align: center;
          padding: rem-calc(12);
          font-weight: 700;
          font-size: 15px;
          margin-bottom: rem-calc(12);
          display: none;
          &.success{
            display: block;
            color: $success;
            border: rem-calc(1) solid $success;
          }
          &.error{
            display: block;
            color: $danger;
            border: rem-calc(1) solid $danger;
          }
        }
        .form{
          &-label{
            display: block;
            margin: 0;
            font-size: rem-calc(14);
            font-weight: 700;
            line-height: 2;
            color: $primary;
            text-transform: uppercase;
          }
          &-control,
          &-select{
            background-color: $secondary;
            color: $primary-light;
            &.error{
              border-color: $danger;
              // ~ .form-label {
              //   color: $danger !important;
              // }
              & + .form-label {
                color: $danger !important;
              }
              & + .form-error {
                display: block;
              }
            }
            &:focus{
              color: $primary;
              border-color: $primary;
              outline: 0;
              -webkit-box-shadow: none;
              box-shadow: none;
            }
          }
          &-select{
            --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e2dbd2' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
          }
          &-check{
            &-input{
              border-radius: 50%;
              border: rem-calc(2) solid $primary;
              background-color: $secondary;
              transition: all ease 0.4s;
              &.error{
                border-color: $danger;
                & + .form-check-label {
                  color: $danger !important;
                }
              }
              &:checked[type=checkbox] {
                --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ae9752'/%3e%3c/svg%3e");
              }
              &:focus{
                color: $primary;
                border-color: $primary;
                outline: 0;
                -webkit-box-shadow: none;
                box-shadow: none;
              }
            }
            &-label{
              font-size: rem-calc(14);
              &:hover{
                color: $primary;
              }
              a{
                color: $primary;
              }
            }
          }
          &-error{
            display: none;
            margin-top: rem-calc(4);
            font-size: rem-calc(12);
            font-weight: 700;
            text-transform: uppercase;
            color: $danger;
            &.validation-fail{
              display: block;
            }
          }
          .button-wrapper{
            text-align: center;
            position: relative;
            margin-top: rem-calc(40);
            .item-figure-patern{
              position: absolute;
              margin: 0;
              width: 300vw;
              height: 100%;
              max-height: rem-calc(46);
              overflow: hidden;
              z-index: -1;
              bottom: 0;
              right: -100vw;
              background-repeat: repeat;
              background-position: top center;
              background-size: 1.25rem;
              img{
                display: none;
              }
            }
            .newsletter-submit{
              color: $primary;
              background-color: $secondary;
              border: rem-calc(2) solid $primary;
              font-size: rem-calc(32);
              font-weight: 700;
              text-transform: uppercase;
              padding: rem-calc(8) rem-calc(20) rem-calc(2) rem-calc(20);
              transition: all ease 0.4s;
              line-height: 1;
              &:hover{
                color: $primary-light;
                border: rem-calc(2) solid $primary-light;
              }
            }
          }
        }
      }
    }
  }
}