// COLORES DE LA WEB ACTUAL:

$primary:                #ae9752;            // Original
$primary-light:          #e2dbd2;            // Textos claros
$secondary:              #161616;            // Oscuro fondo
$danger:                 #931313;            // Color error
$success:                #99b535;            // Color success

$grid-breakpoints: (
  xs: 0,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1700px
);

@function rem-calc($px) {
  @return math.div($px, 16) * 1rem;
}

// ANIMATIONS 

@keyframes bounce {
  0% {
    color: $secondary;
  }
  50% {
    color: $primary;
  }
}


@keyframes myscroll {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
  }
}

@keyframes myscroll-mobile {
  0% {
    -webkit-transform: translateX(rem-calc(-1000)); /* Menor desplazamiento para móviles */
    transform: translateX(rem-calc(-1000));
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes shake {
  0% { transform: translateX(-50%); }
  25% { transform: translateX(-51%); }
  50% { transform: translateX(-49%); }
  75% { transform: translateX(-51%); }
  100% { transform: translateX(-50%); }
}

@keyframes wipe-in-up {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 0 100% 0);
  }
}

@keyframes wipe-in-down {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translate(-50%, -8px);
            transform: translate(-50%, -8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translate(-50%, 8px);
            transform: translate(-50%, 8px);
  }
  80% {
    -webkit-transform: translate(-50%, 6.4px);
            transform: translate(-50%, 6.4px);
  }
  90% {
    -webkit-transform: translate(-50%, -6.4px);
            transform: translate(-50%, -6.4px);
  }
}

@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translate(-50%, -8px);
            transform: translate(-50%, -8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translate(-50%, 8px);
            transform: translate(-50%, 8px);
  }
  80% {
    -webkit-transform: translate(-50%, 6.4px);
            transform: translate(-50%, 6.4px);
  }
  90% {
    -webkit-transform: translate(-50%, -6.4px);
            transform: translate(-50%, -6.4px);
  }
}
