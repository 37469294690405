.layout-faqs{
  .module-content{
    &-wrapper{
      @include media-breakpoint-up(sm) {
        border-bottom: rem-calc(8) solid $primary;
      }
      .container-fluid{
        position: relative;
        .item-figure-header{
          background-size: cover;
          background-position: center center;
          > img{
            visibility: hidden;
            height: auto;
            width: auto;
            max-width: 100%;
          }
        }
        .header-title{
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate( 50%, -50% );
          text-align: center;
          h2{
            font-size: rem-calc(48);
            font-weight: 700;
            text-transform: uppercase;
            color: $primary;
          }
        }
      }
      .title-accordion{
        color: $primary;
        font-size: rem-calc(20);
        font-weight: 700;
        text-transform: uppercase;
        padding: 0.5rem 1.25rem;
      }
      .accordion {
        &-item{
          border: none;
        }
        &-button {
          padding: 0.5rem 1.25rem;
          color: $primary;
          background-color: $secondary;
          -webkit-box-shadow: none;
          box-shadow: none;
          font-size: rem-calc(20);
          font-weight: 700;
          transition: all ease 0.3s;
          &:hover{
            color: $primary-light;
          }
          &:not(.collapsed) {
            color: $primary;
            background-color: $secondary;
            -webkit-box-shadow: none;
            box-shadow: none;
          }
          &::after{
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23ae9752' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e") !important;
          }
          &.collapsed::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23ae9752' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e") !important;
          }
        }
        &-body{
          color: $primary-light;
          background-color: $secondary;
          padding-top: 0;
          font-size: rem-calc(20);
        }
      }
    }
  }
}