@font-face {
  font-family: 'Anton';
  src: url('../../assets/fonts/Anton-Regular.woff2') format('woff2'),
      url('../../assets/fonts/Anton-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hurme-GS4';
  src: url('../../assets/fonts/HurmeGeometricSans4-Regular.woff2') format('woff2'),
      url('../../assets/fonts/HurmeGeometricSans4-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Androgy';
  src: url('../../assets/fonts/AndrogyDemo.woff2') format('woff2'),
      url('../../assets/fonts/AndrogyDemo.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-Hairline.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-Hairline.woff') format('woff');
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-LightObl.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-LightObl.woff') format('woff');
//   font-weight: 300;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-Light.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-Light.woff') format('woff');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-HairlineObl.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-HairlineObl.woff') format('woff');
//   font-weight: 100;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-BoldObl.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-BoldObl.woff') format('woff');
//   font-weight: bold;
//   font-style: italic;
//   font-display: swap;
// }

@font-face {
  font-family: 'Hurme-GS4';
  src: url('../../assets/fonts/HurmeGeometricSans4-Bold.woff2') format('woff2'),
      url('../../assets/fonts/HurmeGeometricSans4-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-Black.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-Black.woff') format('woff');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-BlackObl.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-BlackObl.woff') format('woff');
//   font-weight: 900;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-SemiBold.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-SemiBold.woff') format('woff');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-RegularObl.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-RegularObl.woff') format('woff');
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-SemiBoldObl.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-SemiBoldObl.woff') format('woff');
//   font-weight: 600;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-Thin.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-Thin.woff') format('woff');
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Hurme-GS4';
//   src: url('../../assets/fonts/HurmeGeometricSans4-ThinObl.woff2') format('woff2'),
//       url('../../assets/fonts/HurmeGeometricSans4-ThinObl.woff') format('woff');
//   font-weight: 100;
//   font-style: italic;
//   font-display: swap;
// }